// Texts
$credits-font-family: "Calibri", sans-serif;
$credits-font-size: 18px;
// Sizes
$social-icon-size: 36px;
$social-icon-padding: 14px;
$social-icon-mobile-size: 22px;
$social-icon-mobile-padding: 8px;
// Colors
$social-default-color: #1a1a1b;
$app-bg-color: #1a1a1b;
$hodash-dev-orange: #f79239;
$social-icon-hover-bg-color: #fff;
$youtube-color: #FF0000;
$meetup-color: #ED1C40;
$linkedin-color: #0077B5;
$facebook-color: #4172B8;
$github-color: #181717;
$easter-egg-heart-color: #e5282e;

body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 80px);
    background-color: $app-bg-color;
    position: relative;
    margin: 0;
    padding: 40px;
    overflow: hidden;
}

#lottie {
    display: flex;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    text-align: center;
    opacity: 1;
}

.home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.home .logo {
    width: 700px;
}

.home .social-icons-wrapper {
    display: flex;
    margin: 40px 0;
}

.home .social-icons-wrapper .social {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $social-icon-padding;
    background: $hodash-dev-orange;
    border-radius: 50%;
    user-select: none;
    cursor: pointer;
    transition: all 0.7s;
}
.home .social-icons-wrapper .social:hover {
    background: $social-icon-hover-bg-color;
    transform: translateY(-4px);
    transition: all 0.7s;
}
.home .social-icons-wrapper .social:not(:last-child) {
    margin-right: 22px;
}
.home .social-icons-wrapper .social a {
    font-size: 0;
}
.home .social-icons-wrapper .social a svg {
    width: $social-icon-size;
    height: $social-icon-size;
    fill: $social-default-color;
    transition: all 0.7s;
}
.home .social-icons-wrapper .social:hover a svg.facebook {
    fill: $facebook-color;
}
.home .social-icons-wrapper .social:hover a svg.linkedin {
    fill: $linkedin-color;
}
.home .social-icons-wrapper .social:hover a svg.meetup {
    fill: $meetup-color;
}
.home .social-icons-wrapper .social:hover a svg.youtube {
    fill: $youtube-color;
}
.home .social-icons-wrapper .social:hover a svg.github {
    fill: $github-color;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    min-height: 20px;
    width: 100%;
    padding: 18px 0;
    color: #f1f1f1;
}
.footer .footer-content {
    display: block;
    text-align: center;
    word-break: break-word;
    font-family: $credits-font-family;
    font-size: $credits-font-size;
    padding: 0 10px;
}

.footer .footer-content .part-one .name {
    font-weight: 700;
}
.footer .footer-content .part-one .name a, .footer .footer-content .part-two .name a {
    color: $hodash-dev-orange;
}
.footer .footer-content .part-two .name {
    font-weight: 700;
}
.footer .footer-content .heart {
    color: #f1f1f1;
    transition: all 0.7s;
}
.footer .footer-content:hover .heart {
    color: $easter-egg-heart-color;
    transition: all 0.7s;
}

@media (max-width: 820px) {
    .home .logo {
        max-width: 100vw;
        width: 400px;
        height: auto;
    }
    .home .social-icons-wrapper .social {
        padding: $social-icon-mobile-padding;
    }

    .home .social-icons-wrapper .social a svg {
        width: $social-icon-mobile-size;
        height: $social-icon-mobile-size;
    }
}
